import { Modal, Drawer, message } from 'ant-design-vue'
import Vue from 'vue'
import FormView from '../components/FormView'
import MapView from '../components/MapView'
import MapMarkersView from '../components/MapMarkersView'
import PopButton from '../components/PopButton'
/**
 * 显示弹窗
 */
export const showModal = function showModal({ title, form, typeData, mode, success, formData, view, viewProps = {} }) {
  const body = document.body
  const div = document.createElement('div')
  body.appendChild(div)
  new Vue({
    el: div,
    data() {
      return {
        visible: true,
      }
    },
    methods: {
      onCancel() {
        this.visible = false
        this.$nextTick(() => {
          const dom = document.getElementsByClassName('ant-modal-root')
          Array.from(dom).forEach((e) => {
            e.parentElement.remove()
          })
        })
      },
      onOk() {
        this.$refs._form.sumbit()
      },
      onSuccess(data) {
        console.log('表单成功', data)
      },
      renderView() {
        const View = view
        return <View props={viewProps} />
      },
    },
    render() {
      return (
        <Modal title={title} visible={this.visible} onCancel={this.onCancel} onOk={this.onOk}>
          {view ? (
            this.renderView()
          ) : (
            <FormView
              ref={'_form'}
              data={form}
              layout={'horizontal'}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              typeData={typeData}
              form={formData}
              mode={mode}
              onSuccess={(data) => success({ data, setHidden: this.onCancel })}
            />
          )}
        </Modal>
      )
    },
  })
}

/**
 * 显示抽屉
 */
export const showDrawer = function showDrawer({
  title,
  form,
  typeData,
  mode,
  success,
  formData,
  width,
  view,
  viewProps = {},
  formProps = {},
  foot = [],
}) {
  const body = document.body
  const div = document.createElement('div')
  div.style.zIndex = 9999
  body.appendChild(div)
  const vm = new Vue({
    el: div,
    data() {
      return {
        visible: true,
        update: false,
      }
    },
    methods: {
      onCancel() {
        this.visible = false
        this.$nextTick(() => {
          const dom = document.getElementsByClassName('ant-modal-root')
          Array.from(dom).forEach((e) => {
            e.parentElement.remove()
          })
        })
      },
      onOk() {
        this.$refs._form.sumbit()
      },
      onSuccess(data) {
        console.log('表单成功', data)
      },
      renderFoot() {
        const footData = typeof foot == 'function' ? foot({ close: this.onCancel, submit: this.onOk }) : foot
        return (
          <div class="drawer-modal-foot">
            {footData.map((e) => {
              return <PopButton item={e} onClick={() => e.onClick({ close: this.onCancel, submit: this.onOk })} />
            })}
          </div>
        )
      },
      renderView() {
        const View = view
        return (
          <View
            ref={'_form'}
            typeData={this.getTypeData()}
            onSuccess={(data) => success({ data, setHidden: this.onCancel })}
            props={viewProps}
          />
        )
      },
      getTypeData() {
        return typeof typeData == 'function' ? typeData() : typeData
      },
      renderBody() {
        return (
          <div class="drawer-modal-body">
            {view ? (
              this.renderView()
            ) : (
              <FormView
                ref={'_form'}
                data={form}
                cols={24}
                typeData={this.getTypeData()}
                form={formData}
                mode={mode}
                props={formProps}
                onSuccess={(data) => success({ data, setHidden: this.onCancel })}
              />
            )}
          </div>
        )
      },
    },
    render() {
      return (
        <Drawer
          title={title}
          visible={this.visible}
          onClose={this.onCancel}
          onOk={this.onOk}
          placement={'right'}
          width={width}
        >
          <div class="drawer-modal-main">
            {this.visible && this.renderBody()}
            {foot && this.renderFoot()}
          </div>
        </Drawer>
      )
    },
  })
  return vm
}

// 打开地图
export const openMap = function openMap({ title, value, onSuccess }) {
  const body = document.body
  const div = document.createElement('div')
  body.appendChild(div)
  new Vue({
    el: div,
    data() {
      return {
        visible: true,
      }
    },
    methods: {
      onCancel() {
        this.visible = false
        this.$nextTick(() => {
          const dom = document.getElementsByClassName('ant-modal-root')
          Array.from(dom).forEach((e) => {
            e.parentElement.remove()
          })
        })
      },
      onOk() {
        const data = this.$refs.map.getData()
        if (data) {
          onSuccess && onSuccess(data)
          this.onCancel()
        } else {
          message.error('请在地图上选择地址')
        }
      },
    },
    render() {
      return (
        <Modal
          title={title}
          okText="确定"
          cancelText="取消"
          visible={this.visible}
          onCancel={this.onCancel}
          onOk={this.onOk}
        >
          <MapView ref="map" value={value} />
        </Modal>
      )
    },
  })
}

// 打开地图标记
export const openMapMarkers = function openMapMarkers({ title, value }) {
  const body = document.body
  const div = document.createElement('div')
  body.appendChild(div)
  new Vue({
    el: div,
    data() {
      return {
        visible: true,
      }
    },
    methods: {
      onCancel() {
        this.visible = false
        this.$nextTick(() => {
          const dom = document.getElementsByClassName('ant-modal-root')
          Array.from(dom).forEach((e) => {
            e.parentElement.remove()
          })
        })
      },
      onOk() {
        this.onCancel()
      },
    },
    render() {
      return (
        <Modal
          title={title}
          okText="确定"
          cancelText="取消"
          visible={this.visible}
          onCancel={this.onCancel}
          onOk={this.onOk}
        >
          <MapMarkersView ref="map" value={value} />
        </Modal>
      )
    },
  })
}

export const validateNumber = (rule, value, callback) => {
  if (value && !/^[0-9]*$/.test(Number(value))) {
    callback('请输入数字')
  } else {
    callback()
  }
}

export const jumpLogin = () => {
  window.location.href = '/login'
}

/**
 * 获取vip金额
 */
export const getVipPrice = (result) => {
  const getVipPic = function (pic1, pic2, index) {
    let price = Math.round(Number(pic1 - (index * (pic1 - pic2)) / 6) * 100) / 100
    return price
  }
  const fill = Array(6).fill('')
  fill.forEach((e, index) => {
    // eslint-disable-next-line prettier/prettier
    const data = result.vipPrice && result.salePrice ? getVipPic(result.salePrice, result.vipPrice, index + 1) : '-'
    result[`v${index + 1}`] = data
  })
  return result
}

// 获取筛选按钮
export const getButtonName = () => {
  const lButton = JSON.parse(localStorage.getItem('button')) || {}
  const currentPath = lButton[location.pathname]
  if (currentPath) {
    return currentPath.map((e) => e.name)
  } else {
    return []
  }
}

export const uploadFile = ({ success } = {}) => {
  const input = document.createElement('input')
  input.type = 'file'
  input.accept = '.png,.jpeg,.jpg'
  input.onchange = function (e) {
    const { token, url } = JSON.parse(localStorage.getItem('qiniu')) || {}
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append('token', token)
    fetch('//upload.qiniup.com/', {
      method: 'POST',
      body: formData,
      headers: {
        token,
      },
    })
      .then((e) => e.json())
      .then((e) => success(url + e.hash))
  }
  input.click()
}

export default {
  showModal,
  showDrawer,
  openMap,
  openMapMarkers,
  validateNumber,
  jumpLogin,
  getVipPrice,
  getButtonName,
  uploadFile,
}
