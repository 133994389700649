<script>
import { Upload } from 'ant-design-vue'
import { getCaption } from '@/utils/util'
export default {
  props: ['value', 'item', 'mode', 'styles'],
  methods: {
    handlePreview() {},
    getImgUrl(list) {
      // '?imageView2/0/format/jpg/q/75'
      const arr = []
      const { url } = JSON.parse(localStorage.getItem('qiniu')) || {}
      list.forEach(e => {
        if (e.status == 'done') {
          if (e.hash) {
            arr.push(url + e.hash)
          } else if (e.url) {
            arr.push(e.url)
          } else {
            if (e.response && e.response.hash) {
              arr.push(url + e.response.hash)
            }
          }
        }
      })
      return arr.join(',')
    },
    getFileList(url) {
      if (Array.isArray(url)) return url
      if (!url) return []
      return url
        .split(',')
        .filter(e => e)
        .map(e => {
          if (!e) return
          const name = e.split('?')[0].split('/')[3]
          return {
            uid: 'vc-upload-' + name,
            name,
            status: 'done',
            url: e,
            thumbUrl: e
          }
        })
    },
    handleChange(data) {
      if (data.file.status == 'uploading') {
        this.$emit('change', data.fileList)
      } else {
        this.$emit('change', this.getImgUrl(data.fileList))
      }
    },
    renderButton() {
      return (
        <div>
          <a-icon type="plus" />
          <div class="ant-upload-text">上传</div>
        </div>
      )
    },
    beforeUpload(file) {
      const str = getCaption(file.name, '.')
      const isJpgOrPng = ['mp4', 'mov', 'avi', 'mpeg', 'wmv'].includes(str)
      if (!isJpgOrPng) {
        this.$message.error('请上传视频文件（mov/mp4/avi/mpeg/wmv）')
        return false
      }
      return isJpgOrPng
    }
  },
  render() {
    const { token } = JSON.parse(localStorage.getItem('qiniu')) || {}
    return (
      <Upload
        name="file"
        action="https://upload.qiniup.com/"
        list-type="picture-card"
        fileList={this.getFileList(this.value)}
        disabled={this.mode == 'detail'}
        class="uploader"
        data={{ token }}
        onChange={this.handleChange}
        style={this.styles}
        beforeUpload={this.beforeUpload}
      >
        {(this.getFileList(this.value) || []).length <
          (this.item.maxLength || 1) && this.renderButton()}
      </Upload>
    )
  }
}
</script>
<style lang="less" scoped>
.uploader {
  margin-left: 0px !important;
}
</style>
